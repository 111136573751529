
import { Component, Prop, Vue } from "vue-property-decorator";
import lookupService from "@/services/lookup.service";
import { Validators } from "@/helpers";
import IngestionTypeUrl from "@/views/datasource/ingestion-types/ingestion-type-url.vue";
import IngestionTypeSftp from "@/views/datasource/ingestion-types/ingestion-type-sftp.vue";
import IngestionTypeDatahub from "@/views/datasource/ingestion-types/ingestion-type-datahub.vue";
import IngestionTypeEtars from "@/views/datasource/ingestion-types/ingestion-type-etars.vue";
import IngestionTypeIfb from "@/views/datasource/ingestion-types/ingestion-type-ifb.vue";
import IngestionTypeManualFileUpload from "@/views/datasource/ingestion-types/ingestion-type-manual-file-upload.vue";
import DataFormatXml from "@/views/datasource/data-formats/data-format-xml.vue";
import DataFormatCsv from "@/views/datasource/data-formats/data-format-csv.vue";
import DataFormatJson from "@/views/datasource/data-formats/data-format-json.vue";
import {
  CsvDataFormatSettingDto,
  CustomIngestionSettingDto,
  CustomIngestionType,
  DataFormatSettingDto,
  DataFormatType,
  DecryptionAlgorithmType,
  DownloadSettingDto,
  ExcelDataFormatSettingDto,
  IngestionType,
  JsonDataFormatSettingDto,
  SourceConfigurationDto,
  XmlDataFormatSettingDto,
} from "@/services/dataservice";
import { VuetifyControl } from "@/plugins/vuetify";
import { constants } from "@/services/constants";
import IngestionIndexingSettingComponent from "./ingestion-indexing-setting.vue";
import DecryptionSettingsComponent from "./decryption-settings.vue";

@Component({
  components: {
    IngestionTypeUrl,
    IngestionTypeSftp,
    IngestionTypeManualFileUpload,
    IngestionTypeDatahub,
    DataFormatXml,
    DataFormatCsv,
    DataFormatJson,
    IngestionIndexingSettingComponent,
    DecryptionSettingsComponent,
    IngestionTypeEtars,
    IngestionTypeIfb,
  },
})
export default class DataSourceDownloadSettings extends Vue {
  @Prop() dataSource: SourceConfigurationDto & {
    downloadSetting: DownloadSettingDto & {
      ingestionSetting: CustomIngestionSettingDto;
    };
  };

  lookup = lookupService;
  DataFormatType = DataFormatType;
  IngestionType = IngestionType;
  CustomIngestionType = CustomIngestionType;

  rules = {
    url: Validators.Required.Url,
    dataFormat: Validators.Required.Value,
    dataSourceType: Validators.Required.Value,
    collectionElementName: Validators.Required.Text,
    cronExpression: [this.validateCronExpression],
  };

  onIngestionTypeChange(ingestionType: IngestionType) {
    // reset datasource settings

    const defaultDataFormatSettings: CsvDataFormatSettingDto = {
      type: DataFormatType.Csv,
      delimiter: ",",
    };

    const sourceConfiguration: SourceConfigurationDto = {
      ...this.dataSource,
      cronExpression:
        ingestionType === IngestionType.ManualFileUpload
          ? constants.cronExpressions.never.value
          : this.dataSource.cronExpression,
      downloadSetting: {
        ingestionIndexingSetting: {
          ...this.dataSource.downloadSetting.ingestionIndexingSetting,
        },
        dataFormatSetting: defaultDataFormatSettings,
        ingestionSetting: {
          type: ingestionType,
        },
        decryptionSettings: {
          type: DecryptionAlgorithmType.None,
        },
      },
    };

    this.$refs.cronExpressionControl.validate();

    this.$emit("onIngestionTypeChange", sourceConfiguration);
  }

  $refs: {
    cronExpressionControl: VuetifyControl;
  };

  onSourceFileUpload(file: File | undefined) {
    if (!file) {
      return;
    }

    let dataFormatSettings: DataFormatSettingDto | null = null;

    switch (file.type) {
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      case "application/vnd.ms-excel":
        dataFormatSettings = { type: DataFormatType.Excel } as ExcelDataFormatSettingDto;
        break;
      case "text/csv":
        dataFormatSettings = {
          type: DataFormatType.Csv,
          delimiter: ",",
        } as CsvDataFormatSettingDto;
        break;
      case "text/xml":
        dataFormatSettings = {
          type: DataFormatType.Xml,
          collectionElementName: "",
        } as XmlDataFormatSettingDto;
        break;
      case "application/json":
        dataFormatSettings = {
          type: DataFormatType.Json,
          elementPath: "",
        } as JsonDataFormatSettingDto;
        break;
    }

    if (dataFormatSettings) {
      this.$emit("onDataFormatSettingChange", dataFormatSettings);
    }

    this.$emit("onFileUpload", file);
  }

  onUrlAuthChange(isAuthEnabled: boolean) {
    this.$emit("onUrlAuthChange", isAuthEnabled);
  }

  get indexingSettingsSummary() {
    let summary =
      `Append ` +
      (this.dataSource.downloadSetting.ingestionIndexingSetting.useAppend ? `enabled` : "disabled");

    if (this.dataSource.downloadSetting.ingestionIndexingSetting.objectId?.length) {
      summary += `, using key '${this.dataSource.downloadSetting.ingestionIndexingSetting.objectId}'`;
    }

    summary += `. Date parsing ` + (this.dataSource.downloadSetting.ingestionIndexingSetting.enableDateDetection ? `enabled` : "disabled");

    return summary;
  }

  private validateCronExpression(cronExpression: string): string | boolean {
    if (this.dataSource.downloadSetting.ingestionSetting.type === IngestionType.ManualFileUpload) {
      // cron is not required when uploading a file manually
      return true;
    }

    if (
      cronExpression === constants.cronExpressions.everyMinute.value &&
      this.dataSource.downloadSetting.ingestionSetting.type !== IngestionType.SFTP
    ) {
      return "This update frequency can only be used with SFTP ingestion";
    }

    return true;
  }

  onShouldReindexAfterSave(shouldReindexAfterSave: boolean) {
    this.$emit("onShouldReindexAfterSave", shouldReindexAfterSave);
  }
}
