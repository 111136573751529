import { MappingConfiguration } from "./index";

export const Vehicle: MappingConfiguration = {
  schema: [
    {
      elementName: "LicensePlate",
      schema: null,
    },
    {
      elementName: "VinNumber",
      schema: null,
    },
  ],
  validationRules: {
    licencePlateOnly: [
      {
        elementName: "LicensePlate",
        required: true,
      },
    ],
    vinNumberOnly: [
      {
        elementName: "VinNumber",
        required: true,
      },
    ],
  },
};
