export const constants = {
  cronExpressions: {
    everyMinute: {
      value: "* * * * *",
      text: "Every minute",
    },
    hourly: {
      value: "0 * * * *",
      text: "Hourly",
    },
    daily: {
      value: "0 18 * * *", // every day at 18:00
      text: "Daily",
    },
    weekly: {
      value: "0 18 * * 0", // every Sunday at 18:00
      text: "Weekly",
    },
    monthly: {
      value: "0 18 1 * *", // every 1st day of the month at 18:00
      text: "Monthly",
    },
    never: {
      value: "0 0 31 2 *",
      text: "Never",
    },
  },

  ClientValidation: {
    DefaultMessage: "Please fill in the required information.",
    DefaultMessageTab: (context: string) =>
      `Please fill in the required information in ${context} tab.`,
  },
};
