
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {IngestionIndexingSettingDto, IngestionType, SourceConfigurationDto} from "@/services/dataservice";
import {Validators} from "@/helpers";

@Component
export default class AppendSettingsComponent extends Vue {
  @Prop() sourceConfiguration: SourceConfigurationDto;

  IngestionType = IngestionType;

  rules = {
    dateFormats: Validators.Required.Array,
  }
  availableDateTimeFormats = [
    {
      text: "Strict date with optional time (yyyy-MM-dd'T'HH:mm:ss.SSSZ or yyyy-MM-dd)",
      value: "strict_date_optional_time"
    },
    {
      text: "Strict date with optional time and nanoseconds (yyyy-MM-dd'T'HH:mm:ss.SSSSSSZ or yyyy-MM-dd)",
      value: "strict_date_optional_time_nanos"
    },
    {text: "Basic date (yyyy/MM/dd)", value: "yyyy/MM/dd"},
    {text: "Basic date (dd/MM/yyyy)", value: "dd/MM/yyyy"},
    {text: "Basic date (yyyyMMdd)", value: "basic_date"},
    {text: "Basic date-time (yyyyMMdd'T'HHmmss.SSSZ)", value: "basic_date_time"},
    {text: "Basic date-time no milliseconds (yyyyMMdd'T'HHmmssZ)", value: "basic_date_time_no_millis"},
    {text: "Basic ordinal date (yyyyDDD)", value: "basic_ordinal_date"},
    {text: "Basic ordinal date-time (yyyyDDD'T'HHmmss.SSSZ)", value: "basic_ordinal_date_time"},
    {text: "Basic ordinal date-time no milliseconds (yyyyDDD'T'HHmmssZ)", value: "basic_ordinal_date_time_no_millis"},
    {text: "Date-time no milliseconds (yyyy-MM-dd'T'HH:mm:ssZ)", value: "date_time_no_millis"},
    {text: "Epoch milliseconds (milliseconds since epoch)", value: "epoch_millis"},
    {text: "Epoch seconds (seconds since epoch)", value: "epoch_second"},
  ]

  get isDataAlreadyImported() {
    return !!this.sourceConfiguration.id;
  }

  originalIngestionIndexingSettings: IngestionIndexingSettingDto;

  mounted() {
    this.originalIngestionIndexingSettings = JSON.parse(JSON.stringify(this.sourceConfiguration.downloadSetting.ingestionIndexingSetting));
  }


  onEnableDateDetectionChanged() {
    if (this.sourceConfiguration.downloadSetting.ingestionIndexingSetting.enableDateDetection) {
      this.sourceConfiguration.downloadSetting.ingestionIndexingSetting.dateTimeFormats ??= [];

      if (this.sourceConfiguration.downloadSetting.ingestionIndexingSetting.dateTimeFormats.length === 0) {
        this.sourceConfiguration.downloadSetting.ingestionIndexingSetting.dateTimeFormats = [
          'strict_date_optional_time',
          'strict_date_optional_time_nanos',
          'yyyy/MM/dd',
          'dd/MM/yyyy'
        ];
      }
    }
  }

  @Watch('sourceConfiguration.downloadSetting.ingestionIndexingSetting', {deep: true})
  emitShouldReindexAfterSave() {
    // when one of those settings are changed, we should reindex the data
    const isChanged =
        this.sourceConfiguration.downloadSetting.ingestionIndexingSetting.enableDateDetection !== this.originalIngestionIndexingSettings.enableDateDetection ||
        this.areEqual(this.sourceConfiguration.downloadSetting.ingestionIndexingSetting.dateTimeFormats, this.originalIngestionIndexingSettings.dateTimeFormats);

    this.$emit('onShouldReindexAfterSave', isChanged);
  }

  private areEqual(array1: Array<unknown> | null, array2: Array<unknown> | null): boolean {

    if(array1 === undefined && array2 === undefined) {
      return true;
    }

    if(array1 === undefined && array2 !== undefined) {
      return false;
    }

    if(array1 !== undefined && array2 === undefined) {
      return false;
    }

    return (array1!.length == array2!.length) && array1!.every(function(element, index) {
      return element === array2![index];
    })
  }
}
