
import { Validators } from "@/helpers";
import lookupService from "@/services/lookup.service";
import { constants } from "@/services/constants";
import tooltip from "@/components/tooltip.vue";
import confirm from "@/components/confirm.dialog.vue";
import DatasourceDownloadSetting from "./datasource-download-setting.vue";
import RestrictionPrivate from "./restriction-settings/restriction-private.vue";
import DatasourceNotificationSettings from "./datasource-notification-settings.vue";
import { eventHub } from "@/eventhub";
import DataSourceMappings from "@/views/datasource/mappings/datasource-mappings.vue";
import DataSourceSchema from "@/views/datasource/schema/datasource-schema.vue";
import { ApiResult } from "@/nswag";
import {
  CsvDataFormatSettingDto,
  DataFormatSettingDto,
  DataFormatType,
  DataSourceService,
  DecryptionAlgorithmType,
  IngestionType,
  SchemaDto,
  SchemaElementDto,
  SchemaMappingDto,
  SourceConfigurationDto,
  UpdateSourceConfigurationDto,
  UrlIngestionSettingDto,
} from "@/services/dataservice";
import { VuetifyForm } from "@/plugins/vuetify";
import { Component, Prop, Vue } from "vue-property-decorator";
import { VTab } from "vuetify/lib";

@Component({
  components: {
    DatasourceDownloadSetting,
    DatasourceNotificationSettings,
    RestrictionPrivate,
    DataSourceSchema,
    DataSourceMappings,
    tooltip,
    confirm,
  },
})
export default class DataSource extends Vue {
  @Prop() datasourceId?: string;
  DataFormatType = DataFormatType;
  IngestionType = IngestionType;
  tab: typeof VTab | null = null;
  schema: Array<SchemaElementDto> = [];
  model: SourceConfigurationDto | UpdateSourceConfigurationDto = this.getEmptyDataSource();
  showDialog = true;
  expandSave = false;
  isFormValid = false;
  lookupService = lookupService;
  tenants: Array<string> = [];
  availableTags: Array<string> = [];
  hasFileChanged = false;
  constants = constants;
  showDataSource = false;
  shouldReindexAfterSave = false;

  get showSaveButton() {
    switch (this.model.downloadSetting.ingestionSetting.type) {
      case IngestionType.Url:
      case IngestionType.SFTP:
      case IngestionType.Datahub:
      case IngestionType.Custom:
        return true;
      case IngestionType.AzureBlobStorage:
      case IngestionType.ManualFileUpload:
        // source already exists and no new file has been uploaded
        return this.model.id != null && !this.hasFileChanged && !this.shouldReindexAfterSave;
      default:
        throw new Error("Unsupported ingestion type");
    }
  }

  get showSaveAndRunButton() {
    switch (this.model.downloadSetting.ingestionSetting.type) {
      case IngestionType.Url:
      case IngestionType.SFTP:
      case IngestionType.Datahub:
      case IngestionType.Custom:
        return true;
      case IngestionType.AzureBlobStorage:
      case IngestionType.ManualFileUpload:
        // source is new, new file has been uploaded or some indexing setting that require reindexing has been changed
        return this.model.id == null || this.hasFileChanged || this.shouldReindexAfterSave;
      default:
        throw new Error("Unsupported ingestion type");
    }
  }

  rules = {
    name: Validators.Required.ElasticIndexName,
    restriction: Validators.Required.Value,
    tenant: Validators.Required.Value,
  };

  dataSourceService = new DataSourceService();

  $refs!: {
    form: VuetifyForm;
    mappings?: DataSourceMappings;
  };

  async created() {
    if (this.datasourceId) {
      const dataSourceResponse = await this.dataSourceService.getById(this.datasourceId);

      if (dataSourceResponse.isSuccess) {
        this.model = dataSourceResponse.result;
      }
    }

    this.showDataSource = true;

    const tagsResponse = await this.dataSourceService.getTags();
    if (tagsResponse.isSuccess) {
      this.availableTags = tagsResponse.result;
    }
  }

  async save() {
    if (!this.validate()) {
      return;
    }

    let operationResult: ApiResult<SourceConfigurationDto>;
    if (this.datasourceId) {
      operationResult = await this.dataSourceService.update(
        this.model as UpdateSourceConfigurationDto
      );
    } else {
      if (!this.ensureMappingIsPresentWhenSchemaFileIsUploaded()) {
        return;
      }

      operationResult = await this.dataSourceService.create(this.model);
    }

    if (operationResult.isSuccess) {
      this.showDialog = false;
      this.$emit("saved");
      this.$emit("close");
    }
    return operationResult;
  }

  cancel() {
    this.showDialog = false;
    this.expandSave = false;
    this.$emit("close");
  }

  async saveAndRun() {
    const saveResult = await this.save();
    if (saveResult?.isSuccess && saveResult.result.id)
      await this.dataSourceService.trigger(saveResult.result.id);
  }

  async saveMoreClick() {
    this.expandSave = !this.expandSave;
  }

  validate() {
    const isSourceConfigurationFormValid = this.$refs.form.validate();
    const sourceConfigurationMappingsFormValidationResult =
      this.$refs.mappings?.validateForms() ?? { hasErrors: false };

    if (!isSourceConfigurationFormValid) {
      eventHub.$emit("notification", constants.ClientValidation.DefaultMessageTab("Configuration"));
    }

    if (sourceConfigurationMappingsFormValidationResult.hasErrors) {
      eventHub.$emit("notification", sourceConfigurationMappingsFormValidationResult.errorMessage);
    }

    return (
      isSourceConfigurationFormValid && !sourceConfigurationMappingsFormValidationResult.hasErrors
    );
  }

  changeRestriction(restriction: string) {
    this.model = {
      ...this.model,
      restrictionSetting: restriction === "Private" ? { allowedTenants: [] } : null,
    };
  }

  onSchemaUploaded(schema: SchemaDto | null) {
    this.model = {
      ...this.model,
      schema: schema,
      mappings: null,
    };
  }

  onMappingsUpdated(mappings: SchemaMappingDto[]) {
    this.model = {
      ...this.model,
      mappings: mappings,
    };
  }

  onIngestionTypeChange(sourceConfiguration: SourceConfigurationDto) {
    this.model = {
      ...sourceConfiguration,
    };
  }

  onDataFormatSettingChange(dataFormatSettings: DataFormatSettingDto) {
    this.model = {
      ...this.model,
      downloadSetting: {
        ...this.model.downloadSetting,
        dataFormatSetting: dataFormatSettings,
      },
    };
  }

  addMapping() {
    this.model.mappings ??= [];

    if (this.model.mappings.length >= 1) {
      console.error("At the moment we do not support multiple mapping in the UI");
      return;
    }

    this.model.mappings.push({
      type: lookupService.dataSourceMappingType[0].value, // take first available as default
      mappings: [],
    });
  }

  removeMapping() {
    this.model.mappings!.splice(0, this.model.mappings!.length);
  }

  getEmptyDataSource() {
    const defaultIngestionSettings: UrlIngestionSettingDto = {
      type: IngestionType.Url,
      fileSources: [{ url: "", password: null }],
    };

    const defaultDataFormat: CsvDataFormatSettingDto = {
      type: DataFormatType.Csv,
      delimiter: ",",
    };

    const dataSource: SourceConfigurationDto = {
      id: null,
      cronExpression: constants.cronExpressions.daily.value,
      restrictionSetting: null,
      downloadSetting: {
        dataFormatSetting: defaultDataFormat,
        ingestionSetting: defaultIngestionSettings,
        ingestionIndexingSetting: {
          objectId: null,
          useAppend: false,
          enableDateDetection: false,
          dateTimeFormats: [],
        },
        decryptionSettings: {
          type: DecryptionAlgorithmType.None,
        },
      },
      notificationSettings: [],
      secureStringContainer: {},
      name: "",
      description: "",
      schema: null,
      mappings: null,
      tags: [],
    };

    return dataSource;
  }

  isUpdate(
    sourceConfiguration: SourceConfigurationDto | UpdateSourceConfigurationDto
  ): sourceConfiguration is UpdateSourceConfigurationDto {
    return !!sourceConfiguration.id;
  }

  private ensureMappingIsPresentWhenSchemaFileIsUploaded() {
    if (
      this.model.schema &&
      this.model.schema.length > 0 &&
      (!this.model.mappings || this.model.mappings.length === 0)
    ) {
      // User specified uploaded a new schema file without specifying mappings. Give warning message
      eventHub.$emit("notification", "Please specify mapping for this file");
      return false;
    }

    return true;
  }

  removeTag(tag: string) {
    this.model = {
      ...this.model,
      tags: [...this.model.tags.filter((t) => t !== tag)],
    };
  }
}
